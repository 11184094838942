import Container from '@components/Container'
import { Pagination } from '@components/Pagination'
import SEO from '@components/SEO'
import { css, useTheme } from '@emotion/react'
import { BlogQuery } from '@graphql-types'
import { graphql, navigate, PageProps } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { materialDark as dark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { materialLight as light } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { DiscussionEmbed } from 'disqus-react'
import { BlogComponent } from '@components/BlogComponent'
import rehypeRaw from 'rehype-raw'
import styled from '@emotion/styled'

const BlogContentDiv = styled.div`
	text-align: left;
	max-width: 100%;

	img {
		max-width: 100%;
	}
`

export default function Blogs(props: PageProps<BlogQuery, { id: string }>) {
	const { t } = useTranslation()
	const blog = props.data.blogs
	const theme = useTheme()

	if (blog == null) {
		// navigate('/404')
		return <>{props.pageContext.id}</>
	}

	const disqusConfig = {
		shortname: 'tecky-io',
		config: { identifier: blog.title, title: blog.title },
	}
	return (
		<Container>
			<SEO title={blog.title} keywords={[`blog`]} />
			<h1>{blog.title}</h1>
			<div
				css={css`
					display: flex;
					align-items: center;

					> div {
						margin-right: 1rem;
					}
				`}>
				<GatsbyImage
					css={css`
						border-radius: 50%;
					`}
					image={
						blog.author.profileImgSharp?.childImageSharp
							?.gatsbyImageData
					}
					alt={blog.title}
				/>
				{blog.author.name}
				<br />
				{blog.date}
			</div>
			<BlogContentDiv>
				<ReactMarkdown
					children={blog.content}
					rehypePlugins={[rehypeRaw]} 
					remarkPlugins={[remarkGfm]}
					components={{
						code({ node, inline, className, children, ...props }) {
							const match = /language-(\w+)/.exec(className || '')
							return !inline && match ? (
								<SyntaxHighlighter
									children={String(children).replace(
										/\n$/,
										''
									)}
									style={light}
									css={css`
										max-width: 100%;
									`}
									language={match[1]}
									{...props}
								/>
							) : (
								<code
									css={css`
										max-width: 100%;
										overflow: auto;

										p > & {
											display: inline-block;
											vertical-align: middle;
										}
										pre > & {
											display: block;
										}
									`}
									className={className}
									{...props}>
									{children}
								</code>
							)
						},
					}}
				/>
			</BlogContentDiv>
			<h2>{t('blog.comments')}</h2>
			<DiscussionEmbed
				css={css`
					width: 100%;
				`}
				{...disqusConfig}
			/>
			<h2>{t('blog.readmore')}</h2>
			{blog.relatedBlogs?.map(
				(relatedBlog) =>
					relatedBlog && (
						<BlogComponent
							key={relatedBlog.path}
							blog={relatedBlog}
						/>
					)
			)}
		</Container>
	)
}

export const query = graphql`
	query Blog($language: String!, $id: String!) {
		...TranslationFragment
		blogs: cockpitBlogCollection(
			_id: { eq: $id }
			_lang: { eq: $language }
			active: { eq: true }
		) {
			path
			title
			caption
			content
			author {
				name
				profileImg {
					path
				}
				profileImgSharp {
					childImageSharp {
						gatsbyImageData(width: 35, placeholder: BLURRED)
					}
				}
			}
			date
			coverImage {
				path
			}
			coverImageSharp {
				childImageSharp {
					gatsbyImageData(
						width: 300
						height: 300
						transformOptions: { fit: CONTAIN }
					)
				}
			}
			relatedBlogs {
				path
				title
				caption
				date
				author {
					name
					profileImg {
						path
					}
					profileImgSharp {
						childImageSharp {
							gatsbyImageData(width: 35, placeholder: BLURRED)
						}
					}
				}
				coverImage {
					path
				}
				coverImageSharp {
					childImageSharp {
						gatsbyImageData(
							width: 300
							height: 300
							transformOptions: { fit: CONTAIN }
						)
					}
				}
			}
		}
	}
`
